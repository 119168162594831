@import '../../../styles/colors.css';

.modal {
  max-width: 500px;
  min-height: 100px;
  box-sizing: border-box;
  padding: 30px;
  position: relative;
}

.actions {
  color: var(--color-department);
  padding-top: 30px;
  cursor: pointer;
  text-align: center;
}

.cancel {
  padding-top: 30px;
  margin-right: 20px;
  cursor: pointer;
}
