@import '../../../styles/colors.css';
@import '../../../styles/fontsize.css';

.wrapper {
  display: none;
}

.container {
  display: flex;
  width: 400px;
  background-color: green;
  border-radius: 10px;
  padding: 5px 0 0 5px;
  z-index: 1000;
}

.appIcon {
  min-width: 64px;
  width: 50px;
  height: 50px;
  background-color: white;
  margin: 8px 0 0 5px;
  object-fit: cover;
  z-index: 1000;
}

.message {
  height: 64px;
}

.innerTitle {
  color: white;
  font-weight: bold;
}

.innerDescription {
  color: white;
}

.getIcon {
  text-align: center;
  min-width: 75px;
  margin-right: 0;
  margin-left: auto;

  img {
    width: 64px;
    height: 64px;
  }
}

.install {
  font-size: var(--text-xSmall);
  color: var(--color-primary);
  font-weight: bold;
}

@media screen and (max-width: 960px) {
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: white;
    position: sticky;
    z-index: 1000;
  }
  .container {
    width: 95%;
    padding: 5px 0 0 5px;
  }
  .innerTitle {
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding: 3px 0 0 3px;
  }
  .innerDescription {
    color: white;
    font-size: 16px;
    padding: 3px 0 0 3px;
  }
  .message {
    padding-left: 100px;
  }
}
@media screen and (max-width: 480px) {
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: white;
    position: sticky;
    bottom: 0;
    z-index: 1000;
  }
  .innerTitle {
    font-size: 18px;
    padding: 5px 0 0 5px;
  }
  .innerDescription {
    font-size: 9px;
    padding: 5px 0 0 5px;
  }
  .message {
    height: 64px;
    padding-left: 0px;
  }
}
