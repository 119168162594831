@import '../../styles/colors.css';
@import '../../styles/fontsize.css';

.title {
  font-size: var(--text-xxLarge);
  padding-bottom: 30px;
}

.description {
  padding-bottom: 30px;
  text-align: left;
  font-size: var(--text-small);
  color: var(--color-text-sub);
}

.panel {
  width: 600px;
  margin: 0 auto;
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  padding: 100px;
  box-sizing: border-box;
  text-align: center;
}

.text {
  padding: 10px 0 20px;
  font-size: 88%;
  color: var(--color-text-title);
}

.legend {
  text-align: left;
  color: var(--color-text-sub);
  font-weight: bold;
  font-size: 88%;
  padding: 10px 0 5px;
}

.link {
  color: var(--color-secondary);
  font-size: 120%;
}

.inputText {
  border: 1px solid var(--color-border);
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
}

.action {
  padding-top: 20px;
}

.errorText {
  color: var(--color-error);
  padding-bottom: 5px;
}

@media screen and (max-width: 960px) {
  .panel {
    width: 100%;
    padding: 100px 16px;
  }
}
