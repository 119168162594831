.root {
  max-width: 580px;
  margin: 0 auto;
  padding: 8px;
}

.title {
  padding: 30px 0px 10px;
  font-size: 20px;
  font-weight: bold;
}

.name {
  margin-top: 16px;
}

.text {
  white-space: pre-wrap;
  word-break: break-all;
}
