@import '../../styles/colors.css';
@import '../../styles/fontsize.css';

.wrapper {
  padding-top: 20px;
  margin-top: 50px;
  background-color: var(--color-default-background);
}

.wrap {
  width: 1040px;
  margin: 0 auto;
  padding: 0 10px;
  padding-bottom: 20px;
}

.title {
  font-size: 30px;
  color: var(--color-white);
}

.lists {
  display: flex;
  justify-content: center;
}

.list {
  color: var(--color-text-sub);
  font-size: var(--text-small);

  a {
    display: inline-block;
    color: var(--color-text-sub);
  }

  &::after {
    content: '|';
    margin: 0 10px;
  }

  &:last-child::after {
    content: '';
  }
}

.twitter {
  margin-bottom: 10px;
}

.cr {
  font-size: var(--text-xSmall);
  color: var(--color-text-sub);
  text-align: center;
  margin-top: 10px;
}

@media screen and (max-width: 960px) {
  .wrap {
    width: 100%;
    box-sizing: border-box;
  }

  .list {
    color: var(--color-text-sub);

    a {
      display: inline-block;
      color: var(--color-text-sub);
    }

    &::after {
      content: '';
      margin: 0 10px;
    }

    &:last-child::after {
      content: '';
    }
  }
}

@media screen and (max-width: 480px) {
  .list {
    font-size: 10px;
  }
}
