@import "../../styles/colors.css";
@import "../../styles/fontsize.css";

.wrapper {
  height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  text-align: center;
  font-size: var(--text-xxLarge);
  font-weight: bold;
}
