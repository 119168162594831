@import '../../../styles/colors.css';

.modal {
  max-width: 550px;
  min-height: 350px;
  max-height: 80vh;
  box-sizing: border-box;
  position: relative;
  overflow: scroll;
}

.modalHeading {
  padding: 20px 30px 20px 30px;
}

.modalBody {
  padding: 10px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  min-height: 230px;
  justify-content: space-between;
}

.title {
  align-self: center;
  margin-bottom: 0px;
  font-size: 44px;
  text-align: center;
}

.heading {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.content {
  p {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: center;
}

.logo {
  max-width: 80px;
  max-height: 80px;
  align-items: center;
  justify-content: center;
}

.closeText {
  color: var(--color-brand-green);
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  padding-top: 20px;
  text-align: center;
}

@media screen and (min-width: 960px) {
  .modal {
    min-width: 550px;
  }

  .heading {
    font-size: 20px;
  }
}

.actions {
  color: var(--color-department);
  cursor: pointer;
  padding-top: 20px;
  text-align: center;
}
