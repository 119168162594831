@import "../../../styles/colors.css";
@import "../../../styles/fontsize.css";

.button {
  border: none;
  padding: 10px 20px;
  font-size: var(--text-small);
  cursor: pointer;
  border-radius: 5px;
  -webkit-appearance: none;

  &.submit {
    background-color: var(--color-primary);
    color: var(--color-white);
  }

  &.disabled {
    background-color: var(--color-light-gray);
    cursor: default;
  }

  &.normal {
    background-color: var(--color-light-gray);
    font-size: var(--text-small);
    color: var(--color-text-main);
    padding: 8px 20px;
  }

  &.danger {
    background-color: var(--color-danger);
    color: var(--color-white);
  }

  &.large {
    padding: 15px 40px;
    background-color: var(--color-primary);
    font-size: var(--text-default);
    color: var(--color-white);
  }

  &.xLarge {
    padding: 20px 50px;
    background-color: var(--color-primary);
    font-size: var(--text-large);
    color: var(--color-white);
  }

  &.isLoading {
    background: url('/images/loading_white.gif') no-repeat right 10px center, var(--color-primary);
    padding-right: 40px;
    background-size: 20px 20px;
  }
}
