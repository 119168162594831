@import '../../../styles/colors.css';

.modal {
  max-width: 550px;
  min-height: 400px;
  max-height: 80vh;
  box-sizing: border-box;
  position: relative;
  overflow: scroll;
}

.modalHeading {
  padding: 20px 30px 0 30px;
}

.modalBody {
  padding: 10px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: space-between;
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 30px;

  img {
    cursor: pointer;
  }
}


.heading {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
}

.content {
  p {
    margin-bottom: 20px;
    font-size: 110%;
  }
}

@media screen and (min-width: 960px) {
  .modal {
    min-width: 550px;
  }

  .heading {
    font-size: 20px;
  }
}

.selectHospital {
  margin-top: 10px;
  width: 100%;
}

.inputText {
  border: 1px solid var(--color-border);
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
}

.button {
  padding: 0 1em;
  line-height: 2.5em;
  border-bottom: 1px solid #ddd;
  background-color: var(--color-lighter-gray);

  &:hover {
    background-color: var(--color-gray);
    cursor: pointer;
  }
}

.moreButton {
  text-align: center;
  padding: 0 1em;
  line-height: 2.5em;
  border-bottom: 1px solid #ddd;
  background-color: var(--color-lighter-gray);

  &:hover {
    background-color: var(--color-gray);
    cursor: pointer;
  }
}

.actions {
  color: var(--color-department);
  cursor: pointer;
  text-align: center;
}

.cancel {
  padding-top: 30px;
  margin-right: 20px;
  cursor: pointer;
}

.link {
  margin-top: 15px;
  text-align: center;

  a {
    text-decoration: underline;
    color: var(--color-brand-green);
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.hospitalList {
  max-height: 25vh;
  overflow: scroll;
}
