@import "../../../styles/colors.css";
@import "../../../styles/fontsize.css";

.input {
  padding: 8px 10px;
  border: 1px solid var(--color-border);
  font-size: var(--text-default);
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
}
