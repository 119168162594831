@import '../../../styles/colors.css';

.modal {
  max-width: 425px;
}

.modalHeading {
  padding: 20px 30px 20px 30px;
  text-align: center;
}

.userIcon {
  margin-bottom: 24px;
}

.heading {
  font-size: 20px;
  font-weight: bold;
}

.modalBody {
  padding: 10px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  p {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.actions {
  color: var(--color-department);
  cursor: pointer;
  padding-top: 20px;
  text-align: center;
}

.closeText {
  color: var(--color-brand-green);
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  padding-top: 20px;
  text-align: center;
}
