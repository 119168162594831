@import '../../../styles/colors.css';

.modal {
  max-width: 550px;
  min-height: 100px;
  box-sizing: border-box;
  position: relative;
}

.modalHeading {
  padding: 20px 30px 0 30px;
}

.modalBody {
  padding: 10px 30px 30px 30px;
}

.heading {
  font-weight: bold;
  color: var(--color-brand-green);
  margin-bottom: 20px;
  font-size: 18px;
}

.content {
  p {
    margin-bottom: 10px;
    font-size: 110%;
  }

  p.subContent {
    margin-bottom: 10px;
    font-size: 80%;
  }
}

@media screen and (min-width: 960px) {
  .modal {
    min-width: 550px;
  }

  .heading {
    font-size: 20px;
  }
}


.actions {
  color: var(--color-department);
  padding-top: 30px;
  cursor: pointer;
  text-align: center;
}

.cancel {
  padding-top: 30px;
  margin-right: 20px;
  cursor: pointer;
}

.closeActions {
  margin-top: 10px;
  text-align: center;

  button {
    text-decoration: underline;
    color: var(--color-brand-green);
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }
}
