@import '../../../styles/colors.css';
@import '../../../styles/fontsize.css';

.fieldsetRow {
  margin: 10px 0 30px;
  display: flex;
  align-items: center;
}

.fieldsetCol {
  margin: 10px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.legend_row {
  color: var(--color-text-sub);
  font-size: var(--text-medium);
  min-width: 130px;
}

.legend_col {
  color: var(--color-text-sub);
  font-size: var(--text-medium);
  min-width: 130px;
  margin-bottom: 4px;
}

.content {
  flex: 1;
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
}

.required {
  color: var(--color-accent);
  font-weight: normal;
  font-size: 8px;
  margin-left: 4px;
}

@media screen and (max-width: 480px) {
  .fieldset {
    display: block;
  }

  .legend {
    margin-bottom: 8px;
  }
}
