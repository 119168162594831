@import '../../styles/colors.css';
@import '../../styles/fontsize.css';

.logoPanel {
  padding: 10px 100px 40px 100px;
  text-align: center;
}

.subTitle {
  font-weight: bold;
  padding-bottom: 5px;
  font-size: var(--text-xLarge);
}

.panel {
  width: 600px;
  margin: 0 auto;
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  padding: 50px 100px;
  box-sizing: border-box;
  text-align: center;
}

.text {
  padding: 5px 0;
  font-size: 88%;
  color: var(--color-text-title);
}

.hint {
  padding: 10px 0;
  font-size: 88%;
  color: var(--color-text-title);
}

.legend {
  text-align: left;
  color: var(--color-text-sub);
  font-weight: bold;
  font-size: 88%;
  padding: 10px 0 5px;
}

.link {
  color: var(--color-primary);
  font-size: 120%;
}

.inputText {
  border: 1px solid var(--color-border);
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
}

.antaaBtn {
  padding: 8px 20px;
  background-color: transparent;
  color: var(--color-brand-green);
  border: 1px solid var(--color-brand-green);
  font-size: 20px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  height: 60px;

  &:disabled {
    opacity: 0.7;
  }
}

.facebookBtn {
  padding: 8px 20px;
  background-color: var(--color-facebook);
  color: var(--color-white);
  border: none;
  font-size: 20px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;

  &:disabled {
    opacity: 0.7;
  }
}

.action {
  padding-top: 20px;
}

.errorText {
  color: var(--color-error);
  padding-bottom: 5px;
}

.subArea {
  margin-top: 60px;
  border-top: 1px solid var(--color-border);
  padding-top: 30px;
}

.emailHint {
  text-align: left;
  font-size: 12px;
  color: var(--color-dark-gray);
}

@media screen and (max-width: 960px) {
  .logoPanel {
    padding: 5px 20px 10px 20px;
    text-align: center;
  }

  .subTitle {
    font-weight: bold;
    padding-bottom: 5px;
    font-size: var(--text-xSmall);
  }

  .description {
    padding-bottom: 5px;
    font-size: var(--text-xSmall);
  }

  .panel {
    width: 100%;
    box-shadow: none;
    height: 100vh;
    padding: 100px;
  }

  .inputText {
    padding: 16px 20px;
  }
}

@media screen and (max-width: 480px) {
  .description {
    padding-bottom: 5px;
    font-size: 70%;
  }

  .panel {
    padding: 5%;
  }

  .inputText {
    padding: 8px 20px;
  }
}
