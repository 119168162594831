@import "./colors.css";
@import "./fontsize.css";

/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/

html, body, button, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
margin:0;
padding:0;
border:0;
outline:0;
font-size:100%;
vertical-align:baseline;
background:transparent;
}

body {
line-height:1.5;
color: var(--color-text-main);
font-family:-apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
font-size: 14px;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
display:block;
}

nav ul {
list-style:none;
}

blockquote, q {
quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
content:'';
content:none;
}

a {
margin:0;
padding:0;
font-size:100%;
vertical-align:baseline;
background:transparent;
color: var(--color-link);
text-decoration: none;
}

/* change colours to suit your needs */
ins {
background-color:#ff9;
color:#000;
text-decoration:none;
}

/* change colours to suit your needs */
mark {
background-color:#ff9;
color:#000; 
font-style:italic;
font-weight:bold;
}

del {
text-decoration: line-through;
}

abbr[title], dfn[title] {
border-bottom:1px dotted;
cursor:help;
}

table {
border-collapse:collapse;
border-spacing:0;
}

/* change border colour to suit your needs 
*/
hr {
display:block;
height:1px;
border:0; 
border-top:1px solid #cccccc;
margin:1em 0;
padding:0;
}

input, select {
vertical-align:middle;
}

li {
list-style-type:none;
}

input[type=text], input[type=number], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: inherit;
  font-size: 100%;
}

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  border: none;
  box-sizing: border-box;
}

::-webkit-input-placeholder {
color: var(--color-text-disabled);
}
:-moz-placeholder {
color: var(--color-text-disabled); opacity: 1;
}

/* Firefox 19+ */
::-moz-placeholder {
color: var(--color-text-disabled); opacity: 1;
}

/* IE 10+ */
:-ms-input-placeholder {
color: var(--color-text-disabled);
}
