@import "../../styles/colors.css";
@import "../../styles/fontsize.css";

.center {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5001;

  &.isHidden {
    display: none;
  }
}  

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5000;
}

.wrapper {
  position: relative;
  background-color: var(--color-white);
  border-radius: 5px;
  z-index: 5001;
  box-shadow: 0 3px 50px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 960px) {
  .wrapper {
    &.modal {
      width: 90%;
    }
  }
  .wrapper {
    &.confirm {
      max-width: 90%;
    }
  }
}
