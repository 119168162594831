@import '../../styles/colors.css';
.message {
  max-width: 400px;
  margin: 50px auto;
  background-color: var(--color-white);
  padding: 70px 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.content {
  margin: 0 10px;
}
.alignLeft {
  text-align: left;
}

@media screen and (max-width: 960px) {
  .message {
    background-color: var(--color-light-gray);
  }
}
