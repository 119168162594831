@import '../../../styles/colors.css';
@import '../../../styles/fontsize.css';

.select {
  padding: 8px 10px;
  border: 1px solid var(--color-border);
  font-size: var(--text-default);
  box-sizing: border-box;
  border-radius: 5px;
}
