@import '../../styles/colors.css';
@import '../../styles/fontsize.css';

.logoPanel {
  text-align: center;
  background: no-repeat center url(/images/product_creative.png);
  background-size: 600px auto;
  height: 313px;
  width: 600px;
  margin: 0 auto 20px;
  position: relative;
}
.appStoreLink {
  position: absolute;
  right: 193px;
  bottom: 10px;
}
.playStoreLink {
  position: absolute;
  right: 80px;
  bottom: 10px;
}

.panel {
  width: 600px;
  margin: 0 auto;
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  padding: 50px 100px;
  box-sizing: border-box;
  text-align: center;
}

.text {
  padding: 10px 0 10px;
  font-size: var(--text-small);
  color: var(--color-text-title);
}

.hint {
  padding: 5px 0;
  font-size: var(--text-small);
  color: var(--color-text-title);
}

.legend {
  text-align: left;
  color: var(--color-text-sub);
  font-weight: bold;
  font-size: var(--text-small);
  padding: 10px 0 5px;
}

.link {
  color: var(--color-white);
  font-size: var(--text-xLarge);
}

.inputText {
  border: 1px solid var(--color-border);
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
}

.antaaSignInBtn {
  padding: 8px 20px;
  background-color: transparent;
  color: var(--color-brand-green);
  border: 1px solid var(--color-brand-green);
  font-size: 20px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  height: 60px;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
  }
}

.antaaSignUpBtn {
  padding: 8px 20px;
  background-color: var(--color-brand-green);
  color: var(--color-white);
  border: 1px solid var(--color-brand-green);
  font-size: 20px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  height: 60px;
  cursor: pointer;
}

.appleBtn {
  padding: 8px 20px;
  background-color: var(--color-black);
  color: var(--color-white);
  border: none;
  font-size: 20px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  height: 60px;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
  }
}

.facebookBtn {
  padding: 8px 20px;
  background-color: var(--color-facebook);
  color: var(--color-white);
  border: none;
  font-size: 20px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
  }
}

.action {
  padding-top: 20px;
}

.errorText {
  color: var(--color-error);
  padding-bottom: 5px;
}

.subArea {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 30px;
}

.forget {
  cursor: pointer;
  color: var(--color-link);
  display: inline-block;
}

.faq {
  display: inline-block;
}

.faqIcon {
  margin-left: 5px;
}

.helpText {
  margin-bottom: 15px;
  padding: 10px 8px;
  background-color: #bee3f8;
  font-size: 1.1em;
  text-align: left;
}

@media screen and (max-width: 960px) {
  .logoPanel {
    background: url(/images/product_creative.png) no-repeat 0 0 / cover;
    width: 100%;
    padding-top: 52%;
    height: auto;
    position: relative;
  }
  .appStoreLink {
    position: absolute;
    right: 34%;
    bottom: 4%;
  }
  .playStoreLink {
    position: absolute;
    right: 10%;
    bottom: 4%;
  }

  .panel {
    width: 100%;
    box-shadow: none;
    height: 100vh;
    padding: 100px;
  }

  .inputText {
    padding: 16px 20px;
  }

  .antaaBtn {
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .logoPanel {
    background: url(/images/product_creative.png) no-repeat 0 0 / cover;
    width: 100%;
    padding-top: 52%;
    position: relative;
    margin-bottom: 5px;
  }
  .appStoreLink {
    position: absolute;
    right: 26%;
    bottom: 1%;
    width: auto;
    height: auto;
    img {
      width: 80px;
      height: 30px;
    }
  }
  .playStoreLink {
    position: absolute;
    right: 2%;
    bottom: 1%;
    width: auto;
    height: auto;
    img {
      width: 80px;
      height: 30px;
    }
  }
  .panel {
    padding: 5%;
  }

  .inputText {
    padding: 8px 20px;
  }
}
