.wrapper {
  width: 1000px;
  margin: 0 auto;
  padding: 0 10px;
}

@media screen and (max-width: 960px) {
  .wrapper {
    width: 100%;
    padding: 0;
  }
}
