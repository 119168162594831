@import '../../styles/colors.css';
@import '../../styles/spacing.css';

.wrapper {
  padding: 90px 0 0;
  background-color: var(--color-default-background);
}

@media screen and (max-width: 960px) {
  .wrapper {
    padding: var(--spacing-header-height) 0 0;
  }
}
