@import '../../../styles/colors.css';

.modal {
  max-width: 550px;
  min-height: 350px;
  max-height: 80vh;
  box-sizing: border-box;
  position: relative;
  overflow: scroll;
}

.modalHeading {
  padding: 20px 30px 0 30px;
}

.modalBody {
  padding: 10px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  min-height: 230px;
  justify-content: space-between;
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 30px;

  img {
    cursor: pointer;
  }
}


.heading {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
}

.content {
  p {
    margin-bottom: 20px;
    font-size: 110%;
  }
}

@media screen and (min-width: 960px) {
  .modal {
    min-width: 550px;
  }

  .heading {
    font-size: 20px;
  }
}

.selectDepartment {
  width: 100%;
}

.otherDepartment {
  padding-left: 32px;
}


.actions {
  color: var(--color-department);
  cursor: pointer;
  text-align: center;
}

.cancel {
  padding-top: 30px;
  margin-right: 20px;
  cursor: pointer;
}

.closeActions {
  margin-top: 15px;
  text-align: center;

  a, button {
    text-decoration: underline;
    color: var(--color-brand-green);
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.errorText {
  color: var(--color-error);
  font-size: var(--text-small);
}

.hint {
  color: var(--color-text-sub);
  font-size: 10px;
  padding-top: 5px;
  margin-left: 8px;
}
