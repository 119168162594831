@import "../../styles/colors.css";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background-color: var(--color-white);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
}

.logo {
  max-width: 120px;
  max-height: 50px;
}

.right {
  display: flex;
}

.lists {
  display: flex;
  align-items: center;
}

.list {
  margin-right: 30px;

  a {
    color: var(--color-text-title);
    display: block;
    line-height: 24px;
    cursor: pointer;
  }
}

.home {
  background: url('/images/icon_home.svg') no-repeat left center;
  background-size: 20px 20px;
  padding-left: 28px;
}

.logout {
  background: url('/images/icon_logout.svg') no-repeat left center;
  background-size: 20px 20px;
  padding-left: 28px;
}

.profile {
  display: block;
  height: 40px;
}


.profileImg {
  width: 40px;
  height: 40px;
}

.button {
  background-color: var(--color-secondary);
  border: none;
  color: var(--color-white);
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .wrapper {
    box-sizing: border-box;
    padding: 0 8px;
  }
  
  .list {
    margin-right: 16px;
  }

  .list:first-child {
    text-indent:100%;
    white-space:nowrap;
    overflow:hidden;
    width: 28px;
  }

  .list:nth-child(2) {
    text-indent:100%;
    white-space:nowrap;
    overflow:hidden;
    width: 28px;
  }
}

@media screen and (max-width: 480px) {
  .button {
    display: none;
  }
}
